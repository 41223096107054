img {
    border-radius: 10px;
}

.resimg {
    padding: 8px 16px;
}

.resimg:hover {
    transform: translate(0, -5px);
}

.inspace {
    margin-top: 32px;
    text-align: center;
}

.loading {
    text-align: center;
}

.end {
    text-align: center;
}
